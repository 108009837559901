import { Drawer } from "antd";
import React, { useState } from "react";
import { useCustomEventListener } from "react-custom-events";
import { BusinessSidebar } from "../BusinessSidebar";
import { Navbar } from "../Navbar";
import { Sidebar } from "../Sidebar";
import "./Layout.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  selectUser,
  selectSubscriptionTrialStatus,
  notifyMessage,
  selectRoles,
  selectSelectedLocation,
  setSelectedLocation,
  sidebar,
  setAllLocations,
  isChatListExpand,
  initialAlertFetch,
  setInitialAlertFetch,
  notificationAlertData,
  setNotificationData,
  darkmode,
} from "../../../store/authSlice";
import AdminNavBar from "../AdminNavBar/AdminNavBar";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import StickyBanner from "../../atoms/StickyBanner/StickyBanner";
import { useEffect } from "react";
import Location from "../../../api/Location";
import { useLocation, useNavigate } from "react-router-dom";
import AdminSwitchNavBar from "../AdminNavBar/AdminSwitchNavBar";
import CustomNotification from "../../../utils/CustomNotification";
import { Tooltip } from "react-tooltip";
import BusinessBreadCrumbs from "../BreadCrumb/BusinessBreadCrumbs";
import AlertModal from "../../AlertModal";
import Alert from "../../../api/Alert";
import { toast } from "react-toastify";

function DashboardLayout({ children, className, notification = false, darmo }) {
  const roles = useSelector(selectRoles);
  const user = useSelector(selectUser);
  const subscriptionTrialStatus = useSelector(selectSubscriptionTrialStatus);
  const getMessage = useSelector(notifyMessage);
  const [sideBarVisible, setSideBarVisible] = useState(false);
  const [isTrial, setIsTrial] = useState(subscriptionTrialStatus);
  const [isBlocked, setIsBlocked] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const [notificationMessage, setNotificationMessage] = useState(getMessage);
  const isBusiness = roles[0] !== "super-user";
  const isEnterprise = user?.company?.is_enterprise === 1;
  const navigate = useNavigate();
  const location = useLocation();
  const open = useSelector(sidebar);
  const [locations, setLocations] = useState([]);
  const selectedLocation = useSelector(selectSelectedLocation);
  const dispatch = useDispatch();
  const showLocation =
    (isEnterprise && roles[0] !== "employee") ||
    roles[0] === "super-user" ||
    roles[0] === "regional_manager";
  const [showNotification, setShowNotification] = useState({
    show: false,
    type: "",
    message: "",
  });
  const role = roles.length > 0 ? roles[0] : "";
  const [locationStats, setLocationStats] = useState({});
  const isChatListOpen = useSelector(isChatListExpand);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const notificationData = useSelector(notificationAlertData);
  const [loadingAlert, setLoadingAlert] = useState(false);
  const initialFetch = useSelector(initialAlertFetch);
  const mode = useSelector(darkmode);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    dispatch(setNotificationData(null));
  };

  useEffect(() => {
    setCurrentPath(location.pathname);
    LocationStatus();
    loadLocations();
  }, []);

  useCustomEventListener("toggle-sidebar", () => {
    setSideBarVisible(!sideBarVisible);
  });

  useCustomEventListener("toggle-sidebar-close", () => {
    setSideBarVisible(false);
  });

  const FetchSideBar = () => {
    return <>{isBusiness ? <BusinessSidebar /> : <Sidebar />}</>;
  };

  const LocationStatus = async () => {
    Location.locationBlockStatus()
      .then((response) => {
        const status = response.data.is_blocked;

        if (status) {
          setIsBlocked(true);
          navigate(`/business/billing`);
        } else {
          setIsBlocked(false);
        }
      })
      .catch((error) => {});
  };

  const checkAlertNotification = async (id) => {
    try {
      setLoadingAlert(true);
      const response = await Alert.getById(id);
      if (response?.data?.data) {
        dispatch(setNotificationData(response?.data?.data));
      } else if (response?.data?.error === "Notification alert not found") {
        dispatch(setNotificationData(null));
      }
    } catch (error) {
      console.error("Error loading features:", error);
      toast.error("Something went wrong");
    } finally {
      setLoadingAlert(false);
    }
  };

  const loadLocations = async () => {
    const _selectedLocation = await JSON.parse(
      localStorage.getItem("selectedLocation")
    );
    await Location.getAll().then((response) => {
      if (response.data.data) {
        const resLocations = response.data.data;
        setLocations(resLocations);
        dispatch(setAllLocations(resLocations));
        if (resLocations.length > 0 && !selectedLocation) {
          if (showLocation) {
            if (_selectedLocation) {
              dispatch(setSelectedLocation(_selectedLocation));
            } else {
              if (roles[0] === "turnkey_admin") {
              } else {
                dispatch(setSelectedLocation({ name: "Select A Location" }));
              }
            }
          }
        }
      }
    });
  };

  useEffect(() => {
    const loadStats = async (locationId) => {
      try {
        const response = await Location.getLocationStats(locationId);
        setLocationStats((prevStats) => ({
          ...prevStats,
          [locationId]: response?.data?.message,
        }));
      } catch (error) {
        console.error("Error fetching location stats:", error);
      }
    };

    if (isBusiness && currentPath === "/dashboard") {
      locations?.forEach((location) => {
        loadStats(location.id);
      });
    }
  }, [locations]);

  const tooltipStyle = !open ? {} : { display: "none" };

  useEffect(() => {
    if (user && role !== "super-user" && !initialFetch) {
      checkAlertNotification(user?.location?.id);
      dispatch(setInitialAlertFetch(true));
    }
  }, [user, initialFetch]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setSideBarVisible(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div>
        {isTrial && user?.id !== 1 && (
          <div className="">
            <StickyBanner
              content={notificationMessage}
              linkText="Add Payment"
            />
          </div>
        )}

        {isBlocked && user?.id !== 1 && (
          <div className="">
            <StickyBanner
              content="The location is blocked.You must have a valid card on file to continue. One or more of your cards needs to be verified"
              linkText="Review"
            />
          </div>
        )}

        <div className={`w-full h-full ${1 === 1 ? "" : "pt-14"}`}>
          <Drawer
            title={false}
            placement="left"
            onClose={() => setSideBarVisible(false)}
            visible={sideBarVisible}
            closable={false}
            width={`${open ? "280px" : "100px"}`}
            className="mobile-side-menu"
          >
            {/* {FetchSideBar()} */}
            <BusinessSidebar isEnterprise={isEnterprise} isMobile={true} />
          </Drawer>
          {user?.id === 1 ? (
            <div className="flex h-full">
              <div className="w-full flex flex-col h-screen">
                <AdminNavBar />
                <AdminSwitchNavBar />
                <div className="px-4 lg:px-8 h-full overflow-auto bg-white dark:bg-[#18191A]">
                  <div
                    id="page-content"
                    className="h-full max-w-full pt-6 pb-12"
                  >
                    <BreadCrumb darmo={mode} />
                    {children}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex h-full">
              <div className="w-full flex h-screen bg-white dark:bg-[#18191A]">
                <Tooltip
                  id="tooltip-1"
                  style={{ zIndex: 10, ...tooltipStyle }}
                />
                {location?.pathname !== "/subscription" && (
                  <div
                    className={`hidden lg:block relative left-0 ease-in-out duration-200 bg-white dark:bg-[#18191A] ${
                      open
                        ? "md:w-[14%] min-w-[19rem]"
                        : "md:w-[5%] min-w-[90px]"
                    }`}
                  >
                    <BusinessSidebar
                      isEnterprise={isEnterprise}
                      isMobile={false}
                    />
                  </div>
                )}

                <div
                  className={`flex h-full w-full ${
                    location?.pathname === "/subscription" && "lg:w-full"
                  } overflow-hidden`}
                >
                  <div className="flex flex-col h-screen w-full">
                    <Navbar />
                    <div
                      className={`overflow-auto h-full w-full bg-white dark:bg-[#18191A] ${
                        location.pathname !== "/subscription" && "2xl:pr-20"
                      }`}
                    >
                      <div
                        id="page-content"
                        className="p-4 lg:pt-0 lg:px-8 2xl:px-12"
                      >
                        <div className="lg:hidden mb-4">
                          <BusinessBreadCrumbs darmo={mode} />
                        </div>
                        {location.pathname === "/dashboard" &&
                          (role === "regional_manager" || role === "owner") &&
                          selectedLocation?.name === "Select A Location" && (
                            <div>
                              <h1 className="text-xl xl:text-2xl font-bold pb-4 text-gray-900 dark:text-white">
                                Main Dashboard
                              </h1>
                            </div>
                          )}
                        {children}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showNotification?.show && (
        <CustomNotification
          show={showNotification?.show}
          setShow={() =>
            setShowNotification({
              show: false,
              type: showNotification?.type,
              message: showNotification?.message,
            })
          }
          title={showNotification?.message}
          type={showNotification?.type}
        />
      )}
      {!open && <Tooltip id={`sidebar-id`} style={{ zIndex: 9999 }} />}
      {!isChatListOpen && <Tooltip id="my-tooltip-chat" />}
      {role !== "super-user" && !loadingAlert && notificationData && (
        <AlertModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          data={notificationData}
        />
      )}
    </>
  );
}

export default DashboardLayout;
