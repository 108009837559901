import React from "react";
import { NylasSchedulerEditor } from "@nylas/react";
import { DashboardLayout } from "../../../components/organisms/Layout";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/authSlice";
import Scheduler from "../../../api/Scheduler";
import { toast } from "react-toastify";

export default function ScheduleEditorPage() {
  const user = useSelector(selectUser);

  const handleChangeConfig = async (event) => {
    try {
      const payload = {
        user_id: user?.id,
        company_id: user?.company_id,
        config_id: event?.detail?.config?.id,
        email: event?.detail?.config?.participants[0]?.email,
        event_booking: JSON.stringify(event?.detail?.config?.event_booking),
        availability: JSON.stringify({
          duration_minutes:
            event?.detail?.config?.availability?.duration_minutes,
          interval_minutes:
            event?.detail?.config?.availability?.interval_minutes,
        }),
        timestamp: event?.timeStamp,
      };
      await Scheduler.addSchedulerConfig(payload);
      toast.success("Changes saved!");
    } catch (error) {
      toast.error("Error saving the configuration.");
      console.error(error);
    }
  };

  const handleDeleteConfig = async (event) => {
    try {
      await Scheduler.deleteById(event?.detail?.configuration?.id);
      toast.success("Deleted successfully!");
    } catch (error) {
      toast.error("Error deleting the configuration.");
      console.error(error);
    }
  };

  return (
    <DashboardLayout>
      <div className="px-4 py-5 sm:p-6">
        <h1 className="text-2xl font-semibold text-gray-900 dark:text-gray-300 mb-6">
          Scheduler Availability Settings
        </h1>
        <div className="relative">
          <NylasSchedulerEditor
            eventOverrides={{
              schedulerConfigChanged: async (event) => {
                if (event?.type === "schedulerConfigChanged") {
                  handleChangeConfig(event);
                }
              },
              schedulerConfigCreated: async (event) => {
                if (event?.type === "schedulerConfigCreated") {
                  handleChangeConfig(event);
                }
              },
              deleteButtonClick: async (event) => {
                handleDeleteConfig(event);
              },
            }}
            schedulerPreviewLink={`${window.location.origin}/preview?config_id={config.id}`}
            nylasSessionsConfig={{
              clientId: process.env.REACT_APP_NYLAS_CLIENT_ID,
              redirectUri: `${window.location.origin}/scheduler-editor`,
              domain: "https://api.us.nylas.com/v3",
              hosted: true,
              accessType: "offline",
            }}
            defaultSchedulerConfigState={{
              selectedConfiguration: {
                requires_session_auth: false, // Creates a public configuration which doesn't require a session
                scheduler: {
                  // The callback URLs to be set in email notifications
                  rescheduling_url: `${window.location.origin}/reschedule/:booking_ref`, // The URL of the email notification includes the booking reference
                  cancellation_url: `${window.location.origin}/cancel/:booking_ref`,
                },
              },
            }}
            enableUserFeedback={false}
          />
        </div>
      </div>
    </DashboardLayout>
  );
}
