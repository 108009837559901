import axios from "./api";

const Scheduler = {
  addSchedulerConfig(data) {
    return axios.post("/business/nylasConfigStore", data);
  },
  getConfigDetails(user_id) {
    return axios.get(`/business/getConfigDetails/${user_id}`);
  },
  deleteById(config_id) {
    return axios.delete(`/business/deleteConfig/${config_id}`);
  },
  storeBookingData(data) {
    return axios.post("/business/storeInterview", data);
  },
  getInterviewDetails(userId, applicantId, jobPositionId) {
    return axios.get(
      `/business/getInterviewDetails/${userId}/${applicantId}/${jobPositionId}`
    );
  },
  cancelBookingData(data) {
    return axios.post("/business/cancelInterview", data);
  },
  getInterviewByID(configId, bookingId) {
    return axios.get(`/business/get-interview/${configId}/${bookingId}`);
  },
};

export default Scheduler;
