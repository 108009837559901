import React from "react";
import {
  activityGrayIcon,
  activityIcon,
  certificationsGrayIcon,
  certificationsIcon,
  languagesGrayIcon,
  languagesIcon,
  positionsGrayIcon,
  positionsIcon,
  questionIcon,
  questionIconGray,
  skillGrayIcon,
  skillIcon,
} from "../../../assets/images";
import AvailabilityTable from "../../../components/atoms/Tables/AvailabilityTable";
import ResumeViewer from "./ResumeViewer";
import dayjs from "dayjs";
import { DateFormat, DateFormatWithTime } from "../../../utils";

const ApplicantInfo = ({
  mode,
  applicant,
  availability,
  application,
  activityLogs,
}) => {
  return (
    <>
      <div className="rounded-xl border-2 border-gray-300 dark:border-darkGray mb-6 shadow-md">
        <div className="py-2 px-4 flex">
          <i className="fas fa-user text-primary-500 dark:text-gray-500 text-lg mr-2 my-auto" />
          <span className="font-bold text-lg dark:text-gray-300">
            Biography
          </span>
        </div>
        <div className="p-4">
          <p className="text-sm dark:text-gray-500">
            {applicant?.note || "No Biography Available"}
          </p>
        </div>
      </div>

      <div className="flex flex-wrap">
        <div className="w-full md:w-1/2 mb-2 pr-0 md:pr-2">
          <div className="flex flex-col items-center justify-center rounded-xl border-[3px] border-warmgray-200 dark:border-darkGray text-center py-4 md:h-full text-sm md:text-base">
            <i className="fas fa-phone text-primary-500 dark:text-gray-500 text-lg my-auto" />
            <span className="block font-bold text-gray-900 dark:text-warmgray-300">
              Contact Number
            </span>
            <span className="block mt-1 dark:text-warmgray-300">
              {applicant?.phone || "-"}
            </span>
          </div>
        </div>
        <div className="w-full md:w-1/2 mb-2">
          <div className="flex flex-col items-center justify-center rounded-xl border-[3px] border-warmgray-200 dark:border-darkGray text-center py-4 md:h-full text-sm md:text-base">
            <i className="fas fa-envelope text-primary-500 dark:text-gray-500 text-lg my-auto" />
            <span className="block font-bold text-gray-900 dark:text-warmgray-300">
              Email
            </span>
            <span className="block mt-1 dark:text-warmgray-300">
              {applicant?.email}
            </span>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap">
        <div className="w-full md:w-1/2 mb-2 md:mb-0 pr-0 md:pr-2">
          <div className="flex flex-col items-center justify-center rounded-xl border-[3px] border-warmgray-200 dark:border-darkGray text-center py-4 md:h-full text-sm md:text-base">
            <img
              src={mode ? languagesIcon : languagesGrayIcon}
              alt="languages-logo"
              className="w-5 h-5 md:w-6 md:h-6"
            />
            <span className="block font-bold text-gray-900 dark:text-warmgray-300">
              Languages
            </span>
            <span className="block mt-1 dark:text-warmgray-300">
              {applicant?.language || "-"}
            </span>
          </div>
        </div>
        <div className="w-full md:w-1/2">
          <div className="flex flex-col items-center justify-center rounded-xl border-[3px] border-warmgray-200 dark:border-darkGray text-center py-4 md:h-full text-sm md:text-base">
            <img
              src={mode ? certificationsIcon : certificationsGrayIcon}
              alt="certification-logo"
              className="text-primary-500 w-5 h-5 md:w-6 md:h-6 mb-0.5"
            />
            <span className="block font-bold text-gray-900 dark:text-warmgray-300">
              Certifications
            </span>
            <span className="block mt-1 dark:text-warmgray-300">
              {(() => {
                try {
                  const certificationsArray = JSON.parse(
                    applicant?.certifications
                  );
                  return certificationsArray
                    .map((certification) => certification?.name)
                    .join(" / ");
                } catch (error) {
                  return applicant?.certifications !== "null"
                    ? applicant?.certifications
                    : "-";
                }
              })()}
            </span>
          </div>
        </div>
      </div>

      <AvailabilityTable mode={mode} availability={availability} />

      <div className="rounded-xl border-2 shadow-md border-gray-300 dark:border-darkGray my-6">
        <div className="py-3 px-4 flex items-center border-b border-gray-200 dark:border-darkGray">
          <img
            src={mode ? positionsIcon : positionsGrayIcon}
            alt="position-logo"
            className="mr-3 w-5 h-5"
          />
          <span className="font-semibold text-lg dark:text-gray-300">
            Past Positions
          </span>
        </div>

        {applicant?.past_positions && applicant?.past_positions.length > 0 ? (
          <div className="divide-y divide-gray-200 dark:divide-darkGray">
            {applicant?.past_positions
              .sort((a, b) => new Date(b?.start_date) - new Date(a?.start_date))
              .map((p, i) => (
                <div
                  key={i}
                  className="p-4 hover:bg-gray-50 dark:hover:bg-transparent transition-colors"
                >
                  <div className="space-y-2">
                    <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start gap-2">
                      <span className="font-semibold text-gray-900 dark:text-gray-300 break-words">
                        {p?.position}
                      </span>
                      <span className="text-sm text-gray-400 dark:text-gray-500 shrink-0">
                        {dayjs(p?.start_date).format(DateFormat)} -{" "}
                        {p?.end_date === null
                          ? "Present"
                          : dayjs(p?.end_date).format(DateFormat)}
                      </span>
                    </div>

                    <div className="text-sm text-gray-600 dark:text-gray-500">
                      <div className="sm:flex sm:flex-wrap items-center gap-2">
                        <span className="break-words">{p?.employer}</span>
                        {p?.location && (
                          <>
                            <span className="hidden sm:inline">•</span>
                            <span className="break-words">{p?.location}</span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="p-4 text-center text-gray-500 dark:text-gray-400">
            No Past Positions Available
          </div>
        )}
      </div>

      <div className="rounded-xl border-2 shadow-md border-gray-300 dark:border-darkGray my-6">
        <div className="py-2 px-4 flex">
          <img
            src={mode ? skillIcon : skillGrayIcon}
            alt="skill-logo"
            className="mr-2 w-5 h-5 my-auto"
          />{" "}
          <span className="font-bold text-lg my-auto dark:text-gray-300">
            Skills
          </span>
        </div>
        <div className="pb-4 pt-2 px-4">
          {applicant?.skill ? (
            <div className="flex flex-wrap gap-2">
              {applicant?.skill?.split(",")?.map((skill, index) => (
                <div
                  key={index}
                  className="rounded-full border border-primary-500 dark:border-darkGray dark:bg-darkGray px-2 py-1 text-sm dark:text-gray-300"
                >
                  {skill.trim()}
                </div>
              ))}
            </div>
          ) : (
            <p className="dark:text-gray-500">No Skills</p>
          )}
        </div>
      </div>

      {application?.questions && (
        <div className="rounded-xl border-2 shadow-md border-gray-300 dark:border-darkGray mt-2 mb-8">
          <div className="py-2 px-4 flex">
            <img
              src={mode ? questionIcon : questionIconGray}
              alt="activity-logo"
              className="mr-2 w-6 h-6 my-auto"
            />{" "}
            <span className="font-bold text-lg my-auto dark:text-gray-300">
              Questions
            </span>
          </div>
          <div className="divide-y divide-gray-900/10 dark:divide-darkGray px-4 pb-2">
            {JSON.parse(application?.questions).length > 0 ? (
              JSON.parse(application?.questions).map((faq, index) => (
                <div key={index} className="py-4 text-sm md:text-base">
                  <div className="lg:col-span-5 font-bold dark:text-gray-300">
                    {faq?.description}
                  </div>
                  <div className="mt-4 lg:col-span-7 lg:mt-0 dark:text-gray-200">
                    {faq?.answer}
                  </div>
                </div>
              ))
            ) : (
              <div className="dark:text-gray-500 my-2">
                No questions available.
              </div>
            )}
          </div>
        </div>
      )}

      <ResumeViewer applicant={applicant} mode={mode} />

      <div className="rounded-xl border-2 shadow-md border-gray-300 dark:border-darkGray mt-6 mb-12">
        <div className="py-2 px-4 flex">
          <img
            src={mode ? activityIcon : activityGrayIcon}
            alt="activity-logo"
            className="mr-2 w-6 h-6 my-auto"
          />{" "}
          <span className="font-bold text-lg my-auto dark:text-gray-300">
            Activity Log
          </span>
        </div>
        <div className="overflow-auto max-h-56">
          {activityLogs?.map((app, i) => (
            <div key={i}>
              <div className="flex flex-column justify-between p-3 text-xs md:text-sm dark:text-gray-300">
                <span className="flex flex-start mr-1">{app.action}</span>
                <span className="flex flex-end text-gray-500">
                  {dayjs(app.created_at).format(DateFormatWithTime)}
                </span>
              </div>
              <hr className="border-warmgray-200 dark:border-darkGray mx-3" />
            </div>
          ))}
          {activityLogs?.length === 0 && (
            <p className="p-4 max-h-[300px] overflow-y-auto dark:text-gray-500">
              No Activity Logs
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default ApplicantInfo;
