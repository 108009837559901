import React, { useState, useEffect, useRef, useMemo } from "react";
import { Col, Row } from "antd";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  darkmode,
  mobileView,
  selectUser,
  setChatApplicant,
  setJobApplicantTitle,
  setJobPositionTitle,
  setMobileView,
} from "../../../store/authSlice";
import { DashboardLayout } from "../../../components/organisms/Layout";
import Application from "../../../api/Application";
import JobPosition from "../../../api/JobPosition";
import { encodeIds, decodeIds } from "../../../utils";
import ProfileLoading from "../../../components/Loading/ProfileLoading";
import { useTranslation } from "react-i18next";
import JobPositionSelect from "../../../components/atoms/Selector/JobPositionSelect";
import moment from "moment";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import ApplicationActions from "../../../components/molecules/Application/ApplicationActions";
import JobSeeker from "../../../api/JobSeeker";
import ApplicantInfo from "../ViewApplicant/ApplicantInfo";
import ApplicationCardSkeleton from "../../../components/Loading/ApplicationCardSkeleton";
import NotesModal from "../../../components/modals/NotesModal";
import NewConfirmationModal from "../../../components/modals/NewConfirmationModal";
import SchedulingOptionsModal from "../../auth/Scheduler/SchedulingOptionsModal";
import {
  DesktopApplicationCard,
  MobileApplicationCard,
} from "../../../components/molecules/Application/ApplicationCard";

const ViewPositions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mode = useSelector(darkmode);
  const user = useSelector(selectUser);
  const isMobileView = useSelector(mobileView);
  const location = useLocation();
  const applicationsListRef = useRef(null);
  const applicationRefsMap = useRef({});
  const previousPositionRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [loadingApplications, setLoadingApplications] = useState(false);
  const urlParams = location.pathname.split("/");
  const positionId = decodeIds(urlParams[3]);
  const seekerId = urlParams.length > 4 ? decodeIds(urlParams[4]) : null;
  const [activeSegment, setActiveSegment] = useState("Applicants");
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [jobPositions, setJobPositions] = useState([]);
  const [active, setActive] = useState([]);
  const [interviews, setInterviews] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [canceled, setCanceled] = useState([]);
  const [hired, setHired] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [applicant, setApplicant] = useState(null);
  const [loadingApplicant, setLoadingApplicant] = useState(false);
  const [apiAvailabilityData, setApiAvailabilityData] = useState([]);
  const [application, setApplication] = useState({});
  const [activityLogs, setActivityLogs] = useState([]);
  const [showApplicantDetail, setShowApplicantDetail] = useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [type, setType] = useState("");
  const [isSchedulingOpen, setIsSchedulingOpen] = useState(false);
  const [loadingEvent, setLoadingEvent] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isApplicationTransitioning, setIsApplicationTransitioning] =
    useState(false);

  useEffect(() => {
    const checkMobileView = () => {
      dispatch(setMobileView(window.innerWidth < 1280));
    };

    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => window.removeEventListener("resize", checkMobileView);
  }, []);

  const filteredApplications = useMemo(() => {
    let filtered = [];
    switch (activeSegment) {
      case "Applicants":
        filtered = active;
        break;
      case "Interviews":
        filtered = interviews;
        break;
      case "Hired":
        filtered = hired.map((app) => ({ ...app, isHired: true }));
        break;
      case "Declined":
        filtered = [...canceled, ...rejected];
        break;
      default:
        filtered = [];
    }
    return filtered?.sort(
      (a, b) =>
        new Date(b?.seeker?.created_at) - new Date(a?.seeker?.created_at)
    );
  }, [activeSegment, active, interviews, hired, canceled, rejected]);

  const updateURL = (position, application) => {
    if (!position?.value?.id) return;
    const basePath = "/business/applicant";
    const encodedPositionId = encodeIds(position.value.id.toString());
    let newUrl = `${basePath}/${encodedPositionId}`;
    if (application?.seeker?.id) {
      const encodedSeekerId = encodeIds(application.seeker.id.toString());
      newUrl += `/${encodedSeekerId}`;
    }
    window.history.pushState({}, "", newUrl);
  };

  const getActivity = async (id) => {
    try {
      const response = await Application.getApplicationLogs(id);
      setActivityLogs(response.data.data);
    } catch (error) {
      console.error("Error fetching activity logs:", error);
    }
  };

  const loadApplicationDetails = async (positionId, seekerId) => {
    try {
      const app = await Application.getByPositionId(positionId);
      const appls = app.data.data;
      const filteredApplication = appls?.filter(
        (r) => r.job_seeker_id === Number(seekerId) && r.status === "applied"
      );
      if (filteredApplication?.length > 0) {
        const selectedApplication = filteredApplication[0];
        setApplication(selectedApplication);
        getActivity(selectedApplication.id);
      }
    } catch (error) {
      console.error("Error loading application details:", error);
      toast.error("Failed to load application details. Please try again.");
    }
  };

  const loadAvailability = async (id) => {
    try {
      const data = await Application.getByIdForAvailability(id);
      setApiAvailabilityData(data?.data?.availability || []);
    } catch (error) {
      console.error("Error fetching availability:", error);
    }
  };

  const loadApplicationData = async (application) => {
    if (!application?.seeker?.id) return;

    try {
      setLoadingApplicant(true);
      const job = await JobSeeker.getById(application?.seeker?.id);
      setApplicant(job?.data?.data);
      loadAvailability(application?.seeker?.id);
      loadApplicationDetails(
        selectedPosition?.value?.id,
        application?.seeker?.id
      );
    } catch (error) {
      if (error?.response?.status === 403) {
        navigate("/subscription");
      } else {
        toast.error("Failed to load applicant details. Please try again.");
      }
    } finally {
      setLoadingApplicant(false);
    }
  };

  const loadJobPositions = async () => {
    try {
      setLoading(true);
      const response = await JobPosition.getAll();
      const sortedJobs = response.data.data.filter(
        (j) => j?.archived_at == null && j?.deleted_at == null
      );
      setJobPositions(sortedJobs);

      let defaultOption;
      const matchedPosition = sortedJobs.find(
        (job) => job.id === Number(positionId)
      );

      if (matchedPosition) {
        defaultOption = {
          value: matchedPosition,
          label: matchedPosition?.title,
        };
      } else if (sortedJobs.length > 0) {
        defaultOption = {
          value: sortedJobs[0],
          label: sortedJobs[0]?.title,
        };
      }

      if (defaultOption) {
        setSelectedPosition(defaultOption);
      }
    } catch (error) {
      if (error?.response?.status === 403) {
        navigate("/subscription");
      } else {
        toast.error(t("common.something-went-wrong-please-try-again"));
      }
    } finally {
      setLoading(false);
    }
  };

  const loadEmployees = async (positionId) => {
    try {
      const tempUsers = await JobPosition.getHiredEmployees(positionId);
      setHired(tempUsers.data.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        navigate("/subscription");
      } else {
        toast.error("Failed to load Hired Employees. Please try again.");
      }
    }
  };

  const loadApplications = async (positionId) => {
    if (!positionId)
      return { active: [], interview: [], canceled: [], rejected: [] };

    try {
      setLoadingApplications(true);
      document.title = `HeyHire Portal-Viewing-${selectedPosition?.label}`;
      const response = await Application.getByPositionId(positionId);

      const active = response?.data?.data?.filter(
        (r) => r.status === "applied" && r.interview === ""
      );
      const interview = response?.data?.data?.filter(
        (r) => r.status === "applied" && r.interview === "interview scheduled"
      );
      const cancel = response?.data?.data?.filter(
        (r) => r.status === "canceled"
      );
      const reject = response?.data?.data?.filter(
        (r) => r.status === "rejected"
      );

      const sortActive = active?.sort(
        (a, b) => new Date(b?.created_at) - new Date(a?.created_at)
      );

      const sortInterview = interview?.sort(
        (a, b) => new Date(b?.created_at) - new Date(a?.created_at)
      );

      setActive(sortActive || []);
      setInterviews(sortInterview || []);
      setRejected(reject || []);
      setCanceled(cancel || []);

      return {
        active: sortActive || [],
        interview: sortInterview || [],
        canceled: cancel || [],
        rejected: reject || [],
      };
    } catch (error) {
      if (error?.response?.status === 403) {
        navigate("/subscription");
      } else {
        toast.error("Failed to load job. Please try again.");
      }
      return { active: [], interview: [], canceled: [], rejected: [] };
    } finally {
      setLoadingApplications(false);
    }
  };

  const findAndSelectApplication = (applications) => {
    if (!seekerId) return null;

    const seekerApplication = applications.find(
      (app) => app.seeker?.id === Number(seekerId)
    );

    return seekerApplication;
  };

  const handleApplicationSelection = async (
    application,
    isPositionChange = false
  ) => {
    if (!application || (isApplicationTransitioning && !isPositionChange))
      return;

    try {
      setIsApplicationTransitioning(true);
      setSelectedApplication(application);

      await loadApplicationData(application);

      scrollToSelectedApplication(application);
      updateURL(selectedPosition, application);

      if (selectedPosition) {
        dispatch(setJobPositionTitle(selectedPosition));
      }
      dispatch(
        setJobApplicantTitle(
          application?.seeker?.first_name + " " + application?.seeker?.last_name
        )
      );
    } catch (error) {
      console.error("Error selecting application:", error);
      toast.error("Failed to load application details");
    } finally {
      setIsApplicationTransitioning(false);
    }
  };

  const navigateApplication = async (direction) => {
    const currentApplications = filteredApplications;
    if (!selectedApplication || currentApplications.length === 0) return;

    const currentIndex = currentApplications.findIndex(
      (app) => app.id === selectedApplication.id
    );

    let newIndex;
    if (direction === "next") {
      newIndex = (currentIndex + 1) % currentApplications.length;
    } else {
      newIndex =
        (currentIndex - 1 + currentApplications.length) %
        currentApplications.length;
    }

    const newSelectedApplication = currentApplications[newIndex];
    await handleApplicationSelection(newSelectedApplication);
  };

  const scrollToSelectedApplication = (application) => {
    if (!applicationRefsMap.current[application?.id]) return;

    const selectedApplicationElement =
      applicationRefsMap.current[application?.id];
    const containerElement = applicationsListRef.current;

    if (selectedApplicationElement && containerElement) {
      const containerRect = containerElement.getBoundingClientRect();
      const elementRect = selectedApplicationElement.getBoundingClientRect();

      const scrollTop =
        selectedApplicationElement.offsetTop -
        containerElement.offsetTop -
        (containerRect.height / 2 - elementRect.height / 2);

      containerElement.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  };

  const selectFirstApplication = async (applications) => {
    if (applications.length > 0) {
      const firstApplication = applications[0];
      await handleApplicationSelection(firstApplication);
    } else {
      setSelectedApplication(null);
      setApplicant(null);
    }
  };

  useEffect(() => {
    if (user) {
      loadJobPositions();
    }
  }, [user]);

  useEffect(() => {
    const loadInitialData = async () => {
      if (selectedPosition && isInitialLoad) {
        try {
          const { active, canceled, rejected } = await loadApplications(
            selectedPosition?.value?.id
          );
          await loadEmployees(selectedPosition?.value?.id);

          if (seekerId) {
            const allApplications = [
              ...active,
              ...interviews,
              ...canceled,
              ...rejected,
              ...hired,
            ];
            const foundApplication = findAndSelectApplication(allApplications);

            if (foundApplication) {
              // Determine correct segment based on application status
              let targetSegment = "Applicants";

              if (foundApplication.interview === "interview scheduled") {
                targetSegment = "Interviews";
              } else if (
                foundApplication.status === "hired" ||
                foundApplication.isHired
              ) {
                targetSegment = "Hired";
              } else if (
                foundApplication.status === "canceled" ||
                foundApplication.status === "rejected"
              ) {
                targetSegment = "Declined";
              }

              setActiveSegment(targetSegment);
              await handleApplicationSelection(foundApplication);
            } else {
              updateURL(selectedPosition, null);
            }
          } else {
            // If no seekerId, default to showing active applications
            setActiveSegment("Applicants");
            if (active && active.length > 0) {
              await handleApplicationSelection(active[0]);
            }
          }
        } finally {
          setIsInitialLoad(false);
          previousPositionRef.current = selectedPosition?.value?.id;
        }
      }
    };

    loadInitialData();
  }, [selectedPosition, isInitialLoad]);

  useEffect(() => {
    const handlePositionChange = async () => {
      if (
        !selectedPosition?.value?.id ||
        selectedPosition?.value?.id === previousPositionRef.current
      ) {
        return;
      }

      try {
        setLoadingApplications(true);
        const { active } = await loadApplications(selectedPosition.value.id);
        await loadEmployees(selectedPosition.value.id);

        // Reset segment and select first application
        setActiveSegment("Applicants");
        if (active && active.length > 0) {
          await handleApplicationSelection(active[0], true);
        } else {
          setSelectedApplication(null);
          setApplicant(null);
          updateURL(selectedPosition, null);
        }
      } catch (error) {
        console.error("Error handling position change:", error);
        toast.error("Failed to load position data");
      } finally {
        previousPositionRef.current = selectedPosition?.value?.id;
      }
    };

    if (selectedPosition && !isInitialLoad) {
      handlePositionChange();
    }
  }, [selectedPosition]);

  useEffect(() => {
    selectFirstApplication(filteredApplications);
  }, [activeSegment, active, interviews, hired, rejected, canceled]);

  const handleChatApplicant = async (applicant) => {
    dispatch(setChatApplicant(applicant));
  };

  const handleApplicationClick = (application) => {
    setSelectedApplication(application);
    if (isMobileView) {
      setShowApplicantDetail(true);
    }
  };

  const handleOpenNotesModal = () => {
    setIsNotesModalOpen(true);
  };

  const handleCloseNotesModal = () => {
    setIsNotesModalOpen(false);
  };

  const hireApplicant = async (applicantId) => {
    try {
      setLoadingEvent(true);
      await Application.hireByApplicationId(applicantId);
      toast.success("Applicant Hired successfully");
      setIsModalVisible(false);
    } catch (error) {
      setIsModalVisible(false);
      toast.error("an error occurred");
    } finally {
      setLoadingEvent(false);
    }
  };

  const rejectApplication = async (applicantId) => {
    try {
      setLoadingEvent(true);
      await Application.rejectByApplicationId(applicantId);
      toast.success("Applicant Rejected");
      setIsModalVisible(false);
    } catch (error) {
      setIsModalVisible(false);
      toast.error("an error occurred");
    } finally {
      setLoadingEvent(false);
    }
  };

  const handleHireApplicant = () => {
    setType("hire");
    setIsModalVisible(true);
  };

  const handleRejectApplication = () => {
    setType("decline");
    setIsModalVisible(true);
  };

  const handleInterview = () => {
    setIsSchedulingOpen(true);
  };

  const renderApplicationContent = () => {
    if (isApplicationTransitioning) {
      return (
        <div className="w-full text-center py-4 dark:text-gray-300">
          Loading application...
        </div>
      );
    }

    if (loadingApplicant) {
      return (
        <div className="w-full text-center py-4 dark:text-gray-300">
          Loading applicant details...
        </div>
      );
    }

    return (
      applicant && (
        <ApplicantInfo
          mode={mode}
          applicant={applicant}
          availability={apiAvailabilityData}
          application={application}
          activityLogs={activityLogs}
        />
      )
    );
  };

  return (
    <DashboardLayout darmo={true}>
      {loading ? (
        <ProfileLoading />
      ) : (
        <div className="flex flex-col h-full">
          {isMobileView && (
            <div className="flex flex-col h-full">
              {!showApplicantDetail ? (
                <>
                  <div className="flex items-center justify-center">
                    <div className="mt-4 bg-white dark:bg-[#242526] border border-warmgray-200 dark:border-darkGray px-4 py-2 rounded-xl">
                      <JobPositionSelect
                        jobPositions={jobPositions}
                        selectedPosition={selectedPosition}
                        setSelectedPosition={setSelectedPosition}
                        mode={mode}
                      />
                    </div>
                  </div>

                  <div className="flex whitespace-nowrap overflow-x-auto px-4 py-1.5 rounded-xl border-2 border-warmgray-200 dark:border-darkGray bg-warmgray-200 dark:bg-[#242526] my-3">
                    {[
                      { label: "Applicants", count: active.length },
                      // { label: "Messaged Applicants", count: 0 },
                      { label: "Interviews", count: interviews.length },
                      { label: "Hired", count: hired.length },
                      {
                        label: "Declined",
                        count: rejected.length + canceled.length,
                      },
                    ].map((segment, index) => (
                      <button
                        key={segment.label}
                        onClick={() =>
                          segment.count > 0 && setActiveSegment(segment.label)
                        }
                        disabled={segment.count === 0}
                        className={`flex flex-1 items-center justify-center py-1 px-4 bg-warmgray-200 dark:bg-[#242526] text-gray-900 dark:text-gray-300 text-sm 
                        ${
                          index === 4
                            ? "border-none"
                            : "border-r border-warmgray-400"
                        }
                        ${activeSegment === segment.label ? "font-bold" : ""}
                        ${
                          segment.count === 0 && "opacity-50 cursor-not-allowed"
                        }
                        ${
                          activeSegment === segment.label &&
                          segment.count > 0 &&
                          "font-bold"
                        }`}
                      >
                        <span>{segment.label}</span>
                        <span className="ml-1 text-xs">({segment.count})</span>
                      </button>
                    ))}
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-3 overflow-y-auto mb-3">
                    {filteredApplications?.map((application) => (
                      <MobileApplicationCard
                        key={application.id}
                        application={application}
                        onClick={() => handleApplicationClick(application)}
                      />
                    ))}
                  </div>
                </>
              ) : (
                <div className="flex-1 overflow-y-auto">
                  <div className="rounded-xl border-2 border-gray-300 dark:border-darkGray shadow-md bg-white dark:bg-[#242526] mb-6">
                    {selectedApplication && (
                      <div className="flex items-center p-2">
                        <button
                          onClick={() => navigateApplication("prev")}
                          className="p-2 hover:bg-gray-100 dark:hover:bg-darkGray-700 rounded-full transition-colors group"
                        >
                          <FiChevronLeft className="text-gray-600 dark:text-gray-300 w-6 h-6 group-hover:text-primary-500 transition-transform group-hover:-translate-x-1" />
                        </button>

                        <div className="flex flex-col items-center text-center md:text-left md:flex-row w-full mx-2">
                          {selectedApplication?.seeker?.photo ? (
                            <div className="w-32 h-32 md:w-16 md:h-16 flex-shrink-0">
                              <img
                                src={
                                  selectedApplication?.seeker?.photo?.thumb_url
                                }
                                alt={`${selectedApplication?.seeker?.first_name}'s profile`}
                                className="w-full h-full rounded-full object-cover border-2 border-primary-100 shadow-sm"
                              />
                            </div>
                          ) : (
                            <div className="w-32 h-32 md:w-16 md:h-16 bg-primary-100 text-primary-500 rounded-full flex items-center justify-center flex-shrink-0 text-2xl font-bold">
                              {selectedApplication?.seeker?.first_name[0]?.toUpperCase()}
                            </div>
                          )}

                          <div className="mt-4 ml-0 md:ml-4 md:mt-0 whitespace-nowrap">
                            <h2 className="text-lg text-primary-500 dark:text-primary-300 font-bold mb-1">
                              {selectedApplication?.seeker?.first_name}{" "}
                              {selectedApplication?.seeker?.last_name}
                            </h2>
                            <p className="text-xs text-gray-900 dark:text-gray-300 mb-1">
                              {selectedApplication?.seeker?.city &&
                                selectedApplication?.seeker?.state &&
                                `${selectedApplication?.seeker?.city}, ${selectedApplication?.seeker?.state}`}
                            </p>
                            <p className="text-xs text-gray-900 dark:text-gray-300 mb-1">
                              {selectedPosition?.label} Position
                            </p>
                            <p className="text-xs text-gray-900 dark:text-gray-300">
                              Applied{" "}
                              {moment(
                                selectedApplication?.seeker?.created_at
                              ).format("MMM D, YYYY")}
                            </p>
                          </div>

                          <div className="w-full flex justify-center md:justify-end my-3 md:my-0">
                            <ApplicationActions
                              onMessage={() =>
                                handleChatApplicant(selectedApplication)
                              }
                              onInterview={handleInterview}
                              onNotes={() => handleOpenNotesModal()}
                              onHire={() => handleHireApplicant()}
                              onReject={() => {
                                selectedApplication?.isHired
                                  ? rejectApplication()
                                  : handleRejectApplication();
                              }}
                              selectedApplication={selectedApplication}
                            />
                          </div>
                        </div>

                        <button
                          onClick={() => navigateApplication("next")}
                          className="ml-2 p-2 hover:bg-gray-100 dark:hover:bg-darkGray-700 rounded-full transition-colors group"
                        >
                          <FiChevronRight className="text-gray-600 dark:text-gray-300 w-6 h-6 group-hover:text-primary-500 transition-transform group-hover:translate-x-1" />
                        </button>
                      </div>
                    )}
                  </div>

                  <ApplicantInfo
                    mode={mode}
                    applicant={applicant}
                    availability={apiAvailabilityData}
                    application={application}
                    activityLogs={activityLogs}
                  />
                </div>
              )}
            </div>
          )}

          {!isMobileView && (
            <Row gutter={[16, 16]}>
              <Col
                xs={24}
                lg={7}
                className="h-[calc(100vh-150px)] sticky top-0"
              >
                <div
                  className="h-full overflow-y-auto 2xl:pr-2"
                  ref={applicationsListRef}
                >
                  {loadingApplications ? (
                    Array.from({ length: 5 }).map((_, index) => (
                      <ApplicationCardSkeleton key={index} />
                    ))
                  ) : filteredApplications.length === 0 ? (
                    <div className="text-center text-gray-500 py-4">
                      No applications found for {activeSegment} segment
                    </div>
                  ) : (
                    filteredApplications.map((application) => (
                      <DesktopApplicationCard
                        key={application.id}
                        application={application}
                        isSelected={selectedApplication?.id === application?.id}
                        onClick={() => handleApplicationSelection(application)}
                        ref={(el) => {
                          applicationRefsMap.current[application.id] = el;
                        }}
                      />
                    ))
                  )}
                </div>
              </Col>
              <Col xs={24} lg={17}>
                <div className="flex items-center w-full rounded-xl border-2 border-warmgray-200 dark:border-darkGray dark:bg-darkGray mb-6 whitespace-nowrap overflow-x-auto">
                  <div className="flex flex-shrink-0">
                    <JobPositionSelect
                      jobPositions={jobPositions}
                      selectedPosition={selectedPosition}
                      setSelectedPosition={setSelectedPosition}
                      mode={mode}
                    />
                  </div>

                  {[
                    { label: "Applicants", count: active.length },
                    // { label: "Messaged Applicants", count: 0 },
                    { label: "Interviews", count: interviews.length },
                    { label: "Hired", count: hired.length },
                    {
                      label: "Declined",
                      count: rejected.length + canceled.length,
                    },
                  ].map((segment, index) => (
                    <button
                      key={segment.label}
                      onClick={() =>
                        segment.count > 0 && setActiveSegment(segment.label)
                      }
                      disabled={segment.count === 0}
                      className={`flex flex-1 items-center justify-center py-2 px-4 bg-warmgray-200 dark:bg-darkGray text-gray-900 dark:text-gray-300 text-sm 
                  ${
                    index === 4 ? "border-none" : "border-r border-warmgray-400"
                  }
                  ${index === 0 && "border-l border-warmgray-400"}
                  ${activeSegment === segment.label ? "font-bold" : ""}
                  ${segment.count === 0 ? "opacity-50 cursor-not-allowed" : ""}
                  ${
                    activeSegment === segment.label && segment.count > 0
                      ? "font-bold"
                      : ""
                  }`}
                    >
                      <span>{segment.label}</span>
                      <span className="ml-1 text-xs">({segment.count})</span>
                    </button>
                  ))}
                </div>

                {selectedApplication && (
                  <div className="rounded-xl border-2 border-gray-300 dark:border-darkGray mb-6 shadow-md flex items-center px-2 py-6 bg-white dark:bg-[#1c1c1d]">
                    <button
                      onClick={() => navigateApplication("prev")}
                      className="p-2 hover:bg-gray-100 dark:hover:bg-darkGray-700 rounded-full transition-colors group"
                    >
                      <FiChevronLeft className="text-gray-600 dark:text-gray-300 w-6 h-6 group-hover:text-primary-500 transition-transform group-hover:-translate-x-1" />
                    </button>

                    <div className="flex items-center flex-grow mx-4">
                      {selectedApplication?.seeker?.photo ? (
                        <div className="w-16 h-16 flex-shrink-0">
                          <img
                            src={selectedApplication?.seeker?.photo?.thumb_url}
                            alt={`${selectedApplication?.seeker?.first_name}'s profile`}
                            className="w-full h-full rounded-full object-cover border-2 border-primary-100 shadow-sm"
                          />
                        </div>
                      ) : (
                        <div className="w-16 h-16 bg-primary-100 text-primary-500 rounded-full flex items-center justify-center flex-shrink-0 text-2xl font-bold">
                          {selectedApplication?.seeker?.first_name[0]?.toUpperCase()}
                        </div>
                      )}
                      <div className="ml-4">
                        <h2 className="text-lg text-primary-500 dark:text-primary-300 font-bold mb-1">
                          {selectedApplication?.seeker?.first_name}{" "}
                          {selectedApplication?.seeker?.last_name}
                        </h2>
                        <p className="text-xs text-gray-900 dark:text-gray-300 flex items-center mb-1">
                          {selectedApplication?.seeker?.city &&
                            selectedApplication?.seeker?.state &&
                            `${selectedApplication?.seeker?.city}, ${selectedApplication?.seeker?.state}`}
                        </p>
                        <p className="text-xs text-gray-900 dark:text-gray-300 mb-1">
                          {selectedPosition?.label} Position
                        </p>
                        <p className="text-xs text-gray-900 dark:text-gray-300">
                          Applied{" "}
                          {moment(
                            selectedApplication?.seeker?.created_at
                          ).format("MMM D, YYYY")}
                        </p>
                      </div>
                    </div>

                    <ApplicationActions
                      onMessage={() => handleChatApplicant(selectedApplication)}
                      onInterview={handleInterview}
                      onNotes={() => handleOpenNotesModal()}
                      onHire={() => handleHireApplicant()}
                      onReject={() => {
                        selectedApplication?.isHired
                          ? rejectApplication()
                          : handleRejectApplication();
                      }}
                      selectedApplication={selectedApplication}
                    />

                    <button
                      onClick={() => navigateApplication("next")}
                      className="ml-2 p-2 hover:bg-gray-100 dark:hover:bg-darkGray-700 rounded-full transition-colors group"
                    >
                      <FiChevronRight className="text-gray-600 dark:text-gray-300 w-6 h-6 group-hover:text-primary-500 transition-transform group-hover:translate-x-1" />
                    </button>
                  </div>
                )}

                {loadingApplicant ? (
                  <div className="w-full text-center py-4 dark:text-gray-300">
                    Loading applicant details...
                  </div>
                ) : (
                  renderApplicationContent()
                )}
              </Col>
            </Row>
          )}

          <NotesModal
            isOpen={isNotesModalOpen}
            onClose={handleCloseNotesModal}
            seekerId={seekerId}
            positionId={positionId}
            application={selectedApplication}
          />

          <NewConfirmationModal
            open={isModalVisible}
            setOpen={setIsModalVisible}
            type={type}
            onClick={() => {
              if (type === "hire") {
                hireApplicant(selectedApplication?.seeker?.id);
              } else {
                rejectApplication(selectedApplication?.seeker?.id);
              }
            }}
            applicant={selectedApplication?.seeker}
            positionTitle={selectedPosition?.label}
            loading={loadingEvent}
          />

          <SchedulingOptionsModal
            open={isSchedulingOpen}
            setOpen={() => setIsSchedulingOpen(false)}
            applicant={selectedApplication?.seeker}
            positionId={selectedPosition?.value?.id}
          />
        </div>
      )}
    </DashboardLayout>
  );
};

export default ViewPositions;
